import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Best Influencer Marketing Platform | Brandripplr");
    this.metaService.updateTag({ name: 'description', content: 'Discover Brandripplr, the leading influencer marketing platform for effective brand collaboration and management. Boost your campaigns.' });
  }

  ngOnInit(): void {
  }

}
