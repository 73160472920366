import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Celebrate Excellence at the Brandripplr Creator Awards");
    this.metaService.updateTag({ name: 'description', content: 'Join us for the Brandripplr Creator Awards, honoring top influencers. Recognize talent and inspire creativity in the creator community.' });
  }

  isPlaying: Boolean = false;

  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) { 
      this.isPlaying = true;
      myVideo.play();
    }
    else {
      this.isPlaying = false;
      myVideo.pause();
    }
  }

  ngOnInit(): void {
  }

}
