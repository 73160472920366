import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Influencer Terms of Use | Brandripplr");
    this.metaService.addTags([
      {name: 'description', content: 'Review the influencer terms of use for Brandripplr. Understand your rights and obligations as a valued member of our community.'},
    ]);
   }

  ngOnInit(): void {
  }

}
