import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-influence-for-good',
  templateUrl: './influence-for-good.component.html',
  styleUrls: ['./influence-for-good.component.scss']
})
export class InfluenceForGoodComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Influence for Good | Make a Positive Impact with Brandripplr");
    this.metaService.updateTag({ name: 'description', content: 'Learn how Brandripplr empowers influencers to make a positive change. Join us in shaping your brand through meaningful campaigns. Contact us!' });
  }

  isPlaying: Boolean = false;

  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) { 
      this.isPlaying = true;
      myVideo.play();
    }
    else {
      this.isPlaying = false;
      myVideo.pause();
    }
  }

  ngOnInit(): void {
  }

}
