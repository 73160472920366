<div style="background-color:aquamarine; padding:15px 0; font-size: 100%;color:black;">
  <div class="container text-center">
    The 2023 Influencer Marketing Report by Brand Ripplr - <a href="https://mcusercontent.com/8c4544ff69134e8aa1c050137/files/d3a852fa-67bf-fb81-da8f-98a8cd79ce5e/The_2023_Influencer_Marketing_Report.pdf" target="_blank">Download Now</a>
  </div>
</div>
<nav class="container navbar navbar-expand-lg">
  <a class="navbar-brand" routerLink="/">
    <img src="/assets/images/brandripplr.png">
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleNav()">
    <img src="/assets/images/menu.svg">
  </button>
  <div class="navbar-collapse" [ngClass]="{'hide_mobile': !showNav}">
    <button class="navbar-toggler" type="button" (click)="toggleNav()">
      <img src="/assets/images/close.svg">
    </button>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="activePageLink" routerLink="/" [routerLinkActiveOptions]="{exact: true}">Home <span
            class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="activePageLink" routerLink="/influencer-marketing-platform">Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="activePageLink" routerLink="/influencer-marketing-services">Services</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="activePageLink" routerLink="/brands-and-agencies">Brands</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="activePageLink" routerLink="/influencer-platform-for-creators">Influencers</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="activePageLink" routerLink="/creator-awards">Awards</a>
      </li>
      <li class="nav-item">
        <app-signup-popup-button className="sign-up-link">Sign In</app-signup-popup-button>
      </li>
    </ul>
  </div>
</nav>
