import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-brands-and-agencies',
  templateUrl: './brands-and-agencies.component.html',
  styleUrls: ['./brands-and-agencies.component.scss']
})
export class BrandsAndAgenciesComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { 
    this.titleService.setTitle("Collaborate with Top Brands and Agencies | Brandripplr");
    this.metaService.updateTag({ name: 'description', content: 'Connect with leading brands and agencies through Brandripplr. Enhance your influencer marketing strategy and achieve greater success.' });
  }

  isPlaying: Boolean = false;

  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) { 
      this.isPlaying = true;
      myVideo.play();
    }
    else {
      this.isPlaying = false;
      myVideo.pause();
    }
  }

  clients:Array<string> = [
    'assets/images/clients/pepsico.png',
    'assets/images/clients/etisalat.png',
    'assets/images/clients/galaxy.png',
    'assets/images/clients/johnson_and_johnson.png',
    'assets/images/clients/landmark.png',
    'assets/images/clients/masafi.png',
    'assets/images/clients/nestle.png',
    'assets/images/clients/porsche.png',
    'assets/images/clients/pubg.png',
    'assets/images/clients/puma.png',
    'assets/images/clients/vivo.png',
    'assets/images/clients/careem.png',
    'assets/images/clients/quaker.png',
    'assets/images/clients/elc.png',
    'assets/images/clients/zara.png',
  ];
  ngOnInit(): void {
  }

}
