import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Professional Influencer Management Services | Brandripplr");
    this.metaService.updateTag({ name: 'description', content: 'Find top micro-influencer management services in Dubai. Our expert agency helps you to grow your brand with powerful influencer campaigns.' });
  }

  ngOnInit(): void {
  }

}
