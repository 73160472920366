import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-influencers',
  templateUrl: './influencers.component.html',
  styleUrls: ['./influencers.component.scss']
})
export class InfluencersComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Top Influencer Platform for Creators Community | Brandripplr");
    this.metaService.updateTag({ name: 'description', content: 'Discover the best influencer platform for creators. Join our creator community and connect with brands for paid collaborations today.' });
  }

  isPlaying: Boolean = false;

  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) { 
      this.isPlaying = true;
      myVideo.play();
    }
    else {
      this.isPlaying = false;
      myVideo.pause();
    }
  }

  ngOnInit(): void {
  }

}
